/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';

import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons';
import {
  UploadProps,
  message,
  Select,
  Table,
  Space,
  Button,
  Row,
  Col,
  Card,
  Tag,
  Tooltip,
  Input,
  Steps,
  notification,
  Divider,
  Alert,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Dragger from 'antd/lib/upload/Dragger';
import { useQueryClient, useQuery, useMutation } from 'react-query';

import './SliamUploader.scss';

import SearchFilterDropDown from './SearchFilterDropDown';
import StatusColum from './StatusColumn';
import Uploader from './Uploader';
import CSVService from '../../../APIServices/CSVService';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { LogType } from '../../../models/LogType';
import { SliamUploaderConstants } from '../../../utils/constants';
import {
  convertDate,
  convertDateToNumber,
  convertMillisecondsToDHMS,
} from '../../../utils/ConvertDate';
import { sliamUploaderFileType } from '../../../utils/helpers';
import type { NotificationType } from '../../../utils/types';

interface Props {
  sliam?: boolean;
  tab: string;
}

const SliamUploader: React.FC<Props> = ({ sliam, tab }) => {
  const { auth } = useAuth();
  const [uploadFileType, setUploadFileType] = useState('service-coverage');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState<any>('');
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  const [exeUploadButtonLoading, setExeUploadButtonLoading] = useState(false);

  const [disableExeUploadButton, setDisableExeUploadButton] = useState(false);
  const [disableUploadSourceFileButton, setDisableUploadSourceFileButton] =
    useState(false);

  const [searchField, setSearchField] = useState('Select Field');
  const [searchFieldSub, setSearchFieldSub] = useState('Select Field');
  const [api, contextHolder] = notification.useNotification();
  const [isSubField, setIsSubField] = useState(false);
  const [selectSearchTerms, setSelectSearchTerms] = useState<string>('');
  const [disableSearch, setDisableSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentStep, setCurrentStep] = useState<any>(0);
  const [enableSyncButton, setEnableSyncButton] = useState(true);
  const [requestId, setRequestId] = useState<any>('');
  const [uploadFileTypeTitle, setUploadFileTypeTitle] =
    useState('Coverage Map');

  const [inProgress, setInProgress] = useState(false);
  const { Search } = Input;
  const { Option } = Select;

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const downloadSummaryFile = (log: LogType, fileName: string) => {
    axiosPrivate({
      method: 'GET',
      url: `/impex/import/logs/${log.id}/summaryfile`,
      responseType: 'blob',
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        openNotificationWithIcon('success', 'Success', 'Download successful.');
      })
      .catch((err) => {
        openNotificationWithIcon(
          'error',
          'Error',
          'Download failed, some files may be archived and not available for download anymore.'
        );
        console.log(err, 'err');
      });
  };

  const {
    data: recentUploads,
    isLoading,
    refetch: refetchRecentUploads,
    error,
  } = useQuery(['recentUploads', page, pageSize, filters], () =>
    CSVService.getUploadLogs(page, pageSize, filters)
  );

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv, .xlsx',
    showUploadList: false,
    // action: `${process.env.REACT_APP_API_URL}impex/import/${uploadFileType}`,
    customRequest: (options: any) => {
      const formData = new FormData();
      formData.append(
        'file',
        new Blob([options.file], { type: 'text/csv' }),
        options.file.name
      );
      setInProgress(true);
      axiosPrivate({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}impex/import/${uploadFileType}`,
        data: formData,
      })
        .then((res) => {
          options.onSuccess(res, options.file);
          setRequestId(res.data.logId);
          queryClient.invalidateQueries('uploads');
          setInProgress(false);
        })
        .catch((err) => {
          options.onError(err, options.file);
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList, 'uploading');
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        refetchRecentUploads();
        setInProgress(false);
      } else if (status === 'error') {
        refetchRecentUploads();
        setInProgress(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setUploadFileTypeTitle('Coverage Map');
        setUploadFileType('service-coverage');
        break;
      case 1:
        setUploadFileTypeTitle('Customer Service Map');
        setUploadFileType('asset-class-service-mapping');
        break;
      case 2:
        setUploadFileTypeTitle('Customer Site Data');
        setUploadFileType('customer-site-import');
        break;
      case 3:
        setUploadFileTypeTitle('Asset IB Extract');
        setUploadFileType('asset-install-base');
        break;
      case 4:
        setUploadFileTypeTitle('Asset GeoTag Scan');
        setUploadFileType('asset-geotag-scan');
        break;
      case 5:
        setUploadFileTypeTitle('CFS/MCN Data Extract');
        setUploadFileType('mcn-data-extract');
        break;
      case 6:
        setEnableSyncButton(false);
        break;
      default:
        setUploadFileTypeTitle('Coverage Map');
        setUploadFileType('service-coverage');
        break;
    }
  }, [currentStep]);

  const columnsUploads: ColumnsType<LogType> = [
    {
      title: 'Status',
      dataIndex: 'progressStatus',
      key: 'progressStatus',
      render: (_, record) => {
        return <StatusColum record={record} />;
      },
    },
    {
      title: 'File Name',
      dataIndex: 'srcFile',
      key: 'srcFile',
    },
    {
      title: 'Success Count',
      dataIndex: 'successCount',
      key: 'successCount',
      render: (record) => {
        return <Tag color="green">{record}</Tag>;
      },
    },
    {
      title: 'Failed Count',
      dataIndex: 'failedCount',
      key: 'failedCount',
      render: (record) => {
        return (
          <Tooltip title="To view failed records download execution summary">
            <Tag color="red">{record}</Tag>
          </Tooltip>
        );
      },
    },
    {
      title: 'Execution Time',
      dataIndex: 'executionTime',
      key: 'executionTime',
      render: (time) => {
        return (
          <Tag color="default">
            {time ? convertMillisecondsToDHMS(time) : 'Not Available'}
          </Tag>
        );
      },
    },
    {
      title: 'Uploaded By',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
    },
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (_, { fileType }) => {
        return sliamUploaderFileType(fileType);
      },
    },
    {
      title: 'Uploaded',
      dataIndex: 'uploadedAt',
      key: 'uploadedAt',
      sortDirections: ['descend', 'ascend'],
      render: (time) => {
        return convertDate(time);
      },
      sorter: (a, b) =>
        convertDateToNumber(a.uploadedAt!) - convertDateToNumber(b.uploadedAt!),
    },
    {
      title: 'Execution Summary',
      key: 'action',
      render: (_, record) => {
        if (
          record.progressStatus === SliamUploaderConstants.STATUS.COMPLETED &&
          record.summaryFile
        ) {
          return (
            <Space size="middle">
              <Button
                size="small"
                type="primary"
                loading={exeUploadButtonLoading}
                disabled={disableExeUploadButton}
                onClick={() => downloadSummaryFile(record!, record.summaryFile)}
              >
                Download
              </Button>
            </Space>
          );
        }
        return <>Not Currently Available</>;
      },
    },
  ];

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  const setSearchTerms = (value: string) => {
    setSearchFieldSub(value);
    if (
      searchField === SliamUploaderConstants.PROGRESS_STATUS ||
      searchField === SliamUploaderConstants.FILE_TYPE
    ) {
      setFilters(`${searchField}=${value}`);
    } else {
      setFilters(``);
    }
  };

  const setMainSearchTerms = (value: string) => {
    if (
      searchField === SliamUploaderConstants.PROGRESS_STATUS ||
      searchField === SliamUploaderConstants.FILE_TYPE
    ) {
      setFilters(value);
    }
    setSearchField(value);
    setSelectSearchTerms(value);
    setIsSubField(false);
    setSearchFieldSub('');
  };

  useEffect(() => {
    if (
      searchField === SliamUploaderConstants.PROGRESS_STATUS ||
      searchField === SliamUploaderConstants.FILE_TYPE
    ) {
      setIsSubField(true);
      setSearchFieldSub('Select Field');
      setDisableSearch(true);
      setSearchValue('');
    } else {
      setIsSubField(false);
      setSearchFieldSub('Select Field');
      setSelectSearchTerms('');
      setDisableSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSearch = (e: string) => {
    const encodedValue = encodeURIComponent(e.trim());
    setPage(0);
    setPageSize(10);
    setFilters(`${searchField}=${encodedValue}`);
    queryClient.invalidateQueries('recentUploads');
  };

  const processUploads = useMutation({
    mutationFn: () => CSVService.processUploads(),
    onSuccess: (res) => {
      switch (res.data) {
        case SliamUploaderConstants.NO_BATCH_JOB_TO_PROCESS:
          openNotificationWithIcon(
            'warning',
            'No files to process',
            'Please upload files to begin processing.'
          );
          break;
        case SliamUploaderConstants.BATCH_JOB_IN_PROCESS:
          openNotificationWithIcon(
            'info',
            'Sync in progress',
            'Please wait for the current sync to finish.'
          );
          break;
        case SliamUploaderConstants.BATCH_JOB_IN_PIPLINE:
          openNotificationWithIcon(
            'info',
            'Batch added to pipeline',
            'Another Batch in Process and this batch is pushed in the waiting pipeline.'
          );
          break;
        default:
          openNotificationWithIcon(
            'success',
            'Success',
            'Sync in progress, please check the uploads table for updates.'
          );
          break;
      }
      queryClient.clear();
    },
    onError: () => {
      openNotificationWithIcon(
        'error',
        'Failed',
        'Sync failed, please try again or contact support.'
      );
    },
  });

  const clearSearchFilter = () => {
    setSearchValue('');
    setFilters('');
    setSearchField('Select Field');
    setSearchFieldSub('Select Field');
    setDisableSearch(false);
    setPage(0);
    setPageSize(5);
  };

  return (
    <>
      {contextHolder}
      <Row gutter={20}>
        <Col md={24} lg={24} xl={7}>
          <Card
            title="Uploader"
            style={{ marginBottom: 32 }}
            extra={
              <Tooltip title="Click to perform data sync and see updates in the uploads table.">
                <Button
                  type="primary"
                  loading={inProgress}
                  disabled={inProgress}
                  onClick={() => {
                    processUploads.mutate();
                  }}
                >
                  Sync Data
                </Button>
              </Tooltip>
            }
          >
            <div className="alert-download">
              <Alert
                message="Files will be processed in the background once 'Sync Data' button has been clicked. You can view the status updates in the uploads table."
                type="info"
                showIcon
              />
              <br />
            </div>

            <Uploader
              currentStep={currentStep}
              inProgress={inProgress}
              next={next}
              prev={prev}
              dragable={
                <>
                  <Dragger {...props} height={160} disabled={inProgress}>
                    <p className="ant-upload-drag-icon !mb-3">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text !text-sm">
                      <span className="font-semibold">
                        {uploadFileTypeTitle}
                      </span>
                      <br />
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint !text-sm">
                      Support for a single upload.
                    </p>
                  </Dragger>
                </>
              }
            />
            <br />
          </Card>
        </Col>
        <Col md={24} lg={24} xl={17}>
          <Card
            title="Uploads"
            style={{ marginBottom: 22 }}
            extra={
              <Button type="primary" onClick={() => refetchRecentUploads()}>
                Refresh
              </Button>
            }
          >
            <div className="search-bar">
              <Search
                addonBefore={
                  <SearchFilterDropDown
                    searchField={searchField}
                    searchFieldSub={searchFieldSub}
                    setSearchTerms={setSearchTerms}
                    setMainSearchTerms={setMainSearchTerms}
                    clearSearchFilter={clearSearchFilter}
                  />
                }
                placeholder="Search"
                enterButton="Search"
                size="large"
                allowClear
                className={`${disableSearch ? 'disabled' : ''}`}
                disabled={disableSearch}
                onSearch={(e) => handleSearch(e)}
              />
            </div>
            <br />
            <Table
              columns={columnsUploads}
              id="recent-uploads"
              rowKey={(record) => record.id!}
              dataSource={recentUploads?.data}
              loading={isLoading}
              locale={{
                emptyText: <Empty description="No uploads" />,
              }}
              onChange={handleChange}
              pagination={{
                defaultPageSize: 5,
                current: page + 1,
                pageSize,
                total: Number(recentUploads?.headers['x-total-count']),
                pageSizeOptions: ['5', '10', '20', '50', '100'],
                showSizeChanger: true,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SliamUploader;
